import jsPDF from "jspdf"
import { formatDate, DecimalToMonths } from "../../elementos/formatDate";
import imgSrc from '../../images/logo.png'
import imgSrcW from '../../images/logo_w.png'
import icon_insta from '../../images/icons/instagram.png'
import icon_email from '../../images/icons/icon_email.png'
import icon_whats from '../../images/icons/whatsapp.png'
import arrow_info from '../../images/arrow_info.png'
import { Box, Button, CircularProgress, Modal } from "@mui/material";
import { Print } from "@mui/icons-material";
import html2canvas from "html2canvas";
import React, { useEffect, useState } from 'react'

export const ExportarDocumento = ({data, contentRef, validacion = e => console.log("validacion")}) => {
    const [loading, setLoading] = useState(false)
    const [enabled, setEnabled] = useState(true)
    const calcular_anios_faltantes = (data?.edad_nac ? data.edad_nac: 0) - (data?.edad_pico ? data.edad_pico: 0)
    useEffect(()=>{
        setTimeout(()=>{
            setEnabled(false)
        }, 1000)
    },[])
    
    const generate_doc = async () => {
        const input = contentRef.current
        let mensaje_1 = `${formatDate(Date.now())}
        
A quien corresponda,
    
Me dirijo a usted para presentar los hallazgos del análisis del PVC (Pico de Velocidad de Crecimiento) del paciente ${data?.nombre ? data.nombre: ""} y para discutir cómo esta información puede ser aplicada en su desarrollo físico, deportivo y nutricional.

**Hallazgos del PVC:** 

Tipo de Maduración: **${ data?.clase === 1 ? "Precoz": data?.clase === 2 ? "Normal": data?.clase === 3 ? "Tardío": "No definido"}** 
Índice de Maduración: **${data?.indice_mad ? data.indice_mad: 0}** 
Estimación de la Estatura Adulta: **${data?.est_estimada ? parseFloat(data.est_estimada).toFixed(2): ""}** cm.
Edad Estimada para Alcanzar la Estatura Adulta: **${data?.edad_est ? parseFloat(data.edad_est).toFixed(2): ""}** años.
        
**Aplicación Nutricional:** 
Para optimizar el desarrollo físico y deportivo de ${data?.nombre ? data.nombre: ""}, es esencial considerar un enfoque nutricional que complemente su fase de crecimiento actual:
        
**Requerimientos Energéticos:** 
Ajustar la ingesta calórica para apoyar la rápida tasa de crecimiento y el aumento de la masa muscular, según el tipo de maduración y el índice de maduración.

**Macronutrientes:** 
Asegurar un adecuado aporte de proteínas, carbohidratos y grasas saludables para apoyar el desarrollo muscular y el rendimiento deportivo.

**Micronutrientes:** 
Monitorizar el consumo de vitaminas y minerales esenciales, como calcio y vitamina D, que son cruciales para el desarrollo óseo y la salud general.

**Hidratación:** 
Fomentar una hidratación adecuada para mantener el rendimiento físico y apoyar los procesos metabólicos.

**Resultados y Recomendaciones para los Entrenadores:** 

**Adaptación del Programa de Entrenamiento:** 
Personalizar el programa de entrenamiento según la fase de crecimiento de ${data?.nombre ? data.nombre: ""}, priorizando ejercicios que favorezcan el desarrollo equilibrado y eviten lesiones.

**Monitoreo de Progreso:** 
Realizar un seguimiento regular del progreso físico y ajustar los programas de entrenamiento según sea necesario, teniendo en cuenta los cambios en la estatura y el desarrollo muscular.

**Coordinación con el Equipo Nutricional:** 
Trabajar en conjunto con un nutricionista para asegurar que el plan nutricional esté alineado con los objetivos de entrenamiento y las necesidades específicas del paciente.

**Prevención de Lesiones:** 
Implementar estrategias de prevención de lesiones y realizar evaluaciones periódicas para ajustar las cargas de entrenamiento.
Este enfoque integrado entre nutrición y entrenamiento proporcionará una base sólida para maximizar el rendimiento físico y asegurar un desarrollo saludable para ${data?.nombre ? data.nombre: ""}.
`;

        const plantilla = doc => {
            doc.setFillColor("#f47432");
            doc.rect(0, -20, 650, 40, 'F');
            doc.rect(0, 25, 550, 5, 'F');    
            doc.rect(65, 700, 550, 5, 'F');    
            doc.rect(0, 710, 650, 100, 'F');
            doc.setDrawColor(0);
            doc.setFillColor("#ffffff");
            doc.rect(550, 680, 200, 120, 'F'); // Rectángulo relleno (posición x, y, ancho, alto)
            var img_w = new Image()
            img_w.src = imgSrcW
            doc.addImage(img_w, 'png', 474, 644, 180, 180)
            // doc.circle(608, 738, 92, 'F'); //filled red circle with black border  
            var img = new Image()
            img.src = imgSrc
            doc.addImage(img, 'png', 484, 644, 180, 180)
            doc.addImage(icon_insta, 'png', 30, 740, 35, 23)
            doc.setTextColor("#ffffff").setFontSize(10)
            doc.text(`@tunutriologapersonalmx`, 120, 754, {align: 'center'})
            doc.addImage(icon_whats, 'png', 202, 742, 18, 18)
            doc.text(`+528115001317`, 260, 754, {align: 'center'})
            doc.addImage(icon_email, 'png', 321, 742, 18, 18)
            doc.text(`Ln.ivonne.e@hotmail.com`, 401, 754, {align: 'center'})
        }

        html2canvas(input, { scale: 1 })
        .then(async (canvas) => {
            let doc = new jsPDF('portrait', 'pt', 'letter');
            const imgData = canvas.toDataURL('image/png');
            doc.setFontSize(10)
            plantilla(doc)
            var text = data?.nombre ? data.nombre: "Sin Nombre";
            doc.setFontSize(20);
            doc.setTextColor("#f47432")
            doc.text(`${text}`, 300, 300, {align: 'center'}).setFont(undefined, 'bold');
            doc.setFontSize(12);
            doc.setTextColor("#f47432")
            doc.setFont(undefined, 'normal')
            doc.text(`Nutrición Deportiva - Salud - Rendimiento`, 300, 320, {align: 'center'})
            
            doc.setFontSize(10);
            doc.setTextColor("#000000")
            doc.addPage()
            // doc.text(mensaje_1 + mensaje_2, 60, 100,  {maxWidth: 490, align: "left"})
            generateText(mensaje_1, doc)
            plantilla(doc)
            doc.setFontSize(10)
            doc.addPage()
            plantilla(doc)
            doc.setTextColor("#000000")
            doc.addImage(imgData, 'PNG', 70, 70);
            var img_arrow = new Image()
            img_arrow.src = arrow_info
            doc.addImage(img_arrow, 'png', 130, 200, 360, 125)
            let yPos = 400;
            generateText(`${data?.observaciones ? `**Observaciones:** \n${data.observaciones}\n`: ""}\nQuedo a su disposición para cualquier consulta adicional o para coordinar una reunión con el equipo de nutrición y entrenamiento para discutir estos hallazgos en detalle.\n\nAtentamente,\n\n\n\n\n\n\n[Firma]\n${data?.remitente ? data.remitente: ""}\n${data?.titulo?data.titulo:""}`, doc, yPos)
            // doc.text(, 60, yPos,  {maxWidth: 490, align: "left"})
            doc.save(`${data?.nombre ? data.nombre: "paciente"}_${Date.now()}.pdf`);

            setLoading(false)
            return true;
        });
    }

    return(<Box sx={{position: "relative"}}>
        <Button disabled={!loading && enabled ? true: false} onClick={async e => {
            let state_validacion = validacion()
            if(state_validacion){
                setLoading(true)
                await generate_doc()
            }
        }}
            variant="filled" sx={{
            background: "#f47432",
            fontSize: "12px",
            textTransform: "none",
            color: "white",
            fontWeight: "bold",
            marginLeft: "10px"
        }}>
            {!loading && enabled ? (<GradientCircularProgress/>):(<>
                <Print sx={{marginRight: "10px"}}/>
            </>)}
            Imprimir
        </Button>
    </Box>)
}

function GradientCircularProgress() {
    return (
      <React.Fragment>
        <svg width={0} height={0}>
          <defs>
            <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" stopColor="white" />
              <stop offset="100%" stopColor="#ffb282" />
            </linearGradient>
          </defs>
        </svg>
        <CircularProgress size="20px" sx={{ 'svg circle': { stroke: 'url(#my_gradient)' }, marginRight: "10px" }} />
      </React.Fragment>
    );
  }



const generateText = (texto_nuevo="", doc, startY = 60) => {
    const fontSize = 10;
    const lineSpacing = 10.5;

    // ><><><><><><><<><><<><
    // MULTILINE EVENT 2
    // ><><><><><><><<><><<><
    let startX = 60;
    doc.setFont("arial")
    doc.setFontSize(fontSize)
    doc.setFont(undefined, "normal");

    // let texto_nuevo = document.getElementById("multiline2").value;
    // red marks to make textwidth visible
    const regex = /(\*{2})+/g; // all "**" words
    const textWithoutBoldMarks = texto_nuevo.replace(regex, '');

    let splitTextWithoutBoldMarks = doc.splitTextToSize(textWithoutBoldMarks, 490);

    let charsMapLength = 0;
    let position = 0;
    let isBold = false;

    // <><>><><>><>><><><><><>>><><<><><><><>
    // power algorithm to determine which char is bold
    let textRows = splitTextWithoutBoldMarks.map((row, i) => {
        const charsMap = row.split('');

        const chars = charsMap.map((char, j) => {
            position = charsMapLength + j + i;

            let currentChar = texto_nuevo.charAt(position);

            if (currentChar === "*") {
                const spyNextChar = texto_nuevo.charAt(position + 1);
                if (spyNextChar === "*") {
                // double asterix marker exist on these position's so we toggle the bold state
                isBold = !isBold;
                currentChar = texto_nuevo.charAt(position + 2);

                    // now we remove the markers, so loop jumps to the next real printable char
                    let removeMarks = texto_nuevo.split('');
                    removeMarks.splice(position, 2);
                    texto_nuevo = removeMarks.join('');
                }
            }

            return { char: currentChar, bold: isBold };
        });
        charsMapLength += charsMap.length;

        return { ...chars };
    });
    
    const printCharacters = (doc, textObject, startY, startX, fontSize, lineSpacing) => {
        const startXCached = startX;
        const boldStr = 'bold';
        const normalStr = 'normal';

        textObject.map(row => {

            Object.entries(row).map(([key, value]) => {
                doc.setFont(undefined, value.bold ? boldStr : normalStr);

                doc.text(value.char, startX, startY, { align: "left"});
                startX = startX + doc.getStringUnitWidth(value.char) * fontSize;
            });
            startX = startXCached;
            startY += lineSpacing;
        });
    };
    
    printCharacters(doc, textRows, startY, startX, fontSize, lineSpacing);
}