import React, {useReducer} from 'react'
import pacientesReducer from './reducer'
import {Pacientes as PacientesContext} from '../index'
import clientAxios  from '../../config/axios'
import { CREAR_PACIENTES, OBTENER_PACIENTES, SELECCIONAR_PACIENTE } from '../../types'

const Pacientes = props => {
    const initialState = {
        pacientes: [],
        paciente: {}
    }

    const [ state, dispatch ] = useReducer(pacientesReducer, initialState)

    const obtener_pacientes = async (_id = false) => {
        try{
            const result = await clientAxios.post('/api/pacientes/obtener'), result_data = result?.data ? result.data: null
            if(result_data?.pacientes){
                dispatch({
                    type: OBTENER_PACIENTES,
                    payload: result_data.pacientes?.length > 0 ? result_data.pacientes: []
                })
            }
        }catch(err){
            console.log(err)
        }
    }

    const seleccionar_paciente = async (_id, paciente) => {
        try{
            if(paciente){
                dispatch({
                    type: SELECCIONAR_PACIENTE,
                    payload: paciente
                })
            }else{
                console.log(_id)
                const result = await clientAxios.post('/api/pacientes/obtener', {_id: _id});
                
                dispatch({
                    type: SELECCIONAR_PACIENTE,
                    payload: result?.data?.pacientes ? result.data.pacientes: {}
                })
            }
        }catch(err){
            console.log(err)
        }
    }

    const crear_paciente = async paciente => {
        try{
            const result = await clientAxios.post('/api/pacientes', paciente), result_data = result?.data ? result.data: null
            if(result_data?.paciente){
                dispatch({
                    type: CREAR_PACIENTES,
                    payload: result_data.paciente ? result_data.paciente: []
                })

                return 
            }
        }catch(err){
            console.log(err)
        }
    } 

    const eliminar_paciente = async _id => {
        try{
            if(_id){
                const pacientes = state?.pacientes?.length > 0 ? state.pacientes: [], 
                pacientes_eliminado = pacientes.filter(paciente => paciente?._id !== _id); 
                const result = await clientAxios.post('/api/pacientes/modificar', {_id: _id, n_deleted: 1});
                
                if(result?.data?.success){
                    console.log(result)
                    dispatch({
                        type: OBTENER_PACIENTES,
                        payload: pacientes_eliminado
                    })
                }
            }
        }catch(err){
            console.log(err)
        }
    }

    return(
        <PacientesContext.Provider
            value={{
                pacientes: state.pacientes,
                paciente: state.paciente,
                obtener_pacientes,
                seleccionar_paciente,
                crear_paciente,
                eliminar_paciente
            }}
        >
            {props.children}
        </PacientesContext.Provider>
    )
}

export default Pacientes;