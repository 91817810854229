import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import imgsrc from '../images/logo_banner_w.png'
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MailIcon from '@mui/icons-material/Mail';
import { useNavigate } from 'react-router-dom'
import { Article, KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Grid2, Menu, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import {Auth} from '../context';
import { Loading } from './Loading';

function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const authContext = React.useContext(Auth);
  const { cerrarSesion, usuario } = authContext;

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {setAnchorEl(null)};
  const navigate = useNavigate();

  const [letter, setLetter] = React.useState("")

  const cerrar_sesion = () => {
    navigate("/login")
    handleClose()
    localStorage.removeItem("token")
    cerrarSesion()
  }

  React.useEffect(()=>{
    console.log(usuario)
    let nombre = usuario?.s_name ? usuario.s_name: "Undefined";
    if(nombre?.length > 0){
      setLetter(nombre[0])
    }
    
  },[usuario])

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <Avatar sx={{ background: "white",width: 32, height: 32, color: "black", fontSize:"15px" }}>
              {letter.toUpperCase()}
            </Avatar>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
                minWidth: "250px",
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&::before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Box sx={{display: "grid", alignContent: "center", justifyContent: "center", width: "100%"}}>
            <Avatar sx={{width: "60px !important", height: "60px !important", fontSize: "30px !important", margin: "0px !important"}}>
              {letter}
            </Avatar>
          </Box>
        </MenuItem>
        <MenuItem>
          <Box>
            {usuario?.s_name && usuario?.s_legend ? 
            (<Typography sx={{
              fontSize: "12px"
            }}>{usuario.s_legend} {usuario.s_name}</Typography>): null}
          </Box>
        </MenuItem>
        <Divider/>
        <MenuItem onClick={cerrar_sesion}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
            <Typography sx={{fontSize: "14px"}}>
              Salir
            </Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

const drawerWidth = 200;
const fontSize    = "14px";
const iconSize    = "25px";
const logoSize    = "40px";

const NAVIGATION = [
    {
        title: "Evaluaciones",
        icon: <Article/>,
        route: "/evaluaciones"
    }
]

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  variants: [
    {
      props: ({ open }) => open,
      style: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen,
        }),
      },
    },
  ],
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    variants: [
      {
        props: ({ open }) => open,
        style: {
          ...openedMixin(theme),
          '& .MuiDrawer-paper': openedMixin(theme),
        },
      },
      {
        props: ({ open }) => !open,
        style: {
          ...closedMixin(theme),
          '& .MuiDrawer-paper': closedMixin(theme),
        },
      },
    ],
  }),
);

export default function MiniDrawer({children, title = "", acciones=(<Box></Box>)}) {
    const navigate = useNavigate();
    const theme = useTheme();
    const [open, setOpen] = React.useState(true);
    const authContext = React.useContext(Auth);
    const { usuarioAutenticado, usuario } = authContext;

    const token = localStorage.getItem("token")
    React.useEffect(()=>{
      usuarioAutenticado(token)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    React.useEffect(()=>{
      if(usuario === "error"){ navigate("/login")}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[usuario])

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const style_open = {
        position: "absolute",
        bottom: 10,
        right: 10,
        cursor: "pointer",
        fontSize: iconSize
    }

    if(usuario && usuario !== "error"){
      return (<Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={false}>
            <Toolbar sx={{display: "grid", width: "100%", background: "#f47432"}}>
            <Grid2 container>
                <Grid2 size={8}>
                    <Box onClick={e=>navigate("/dashboard")} sx={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        cursor: "pointer"
                    }}>
                        <Box sx={{position: "relative"}}>
                            <Box sx={{background: "white", height: "20px", borderRadius: "100%", position: "absolute", top: "14px", left: "9px", zIndex: 1}}/>
                            <img
                                alt='logo' 
                                style={{zIndex: 2, position: "relative"}}
                                src={imgsrc}
                                // width={logoSize}
                                height={logoSize}
                            />
                        </Box>
                        {/* <Typography sx={{fontSize: "18px", fontWeight: "bold", marginTop: "6px", marginLeft: "5px"}}>Tu Nutrióloga Personal</Typography> */}
                    </Box>
                </Grid2>
                <Grid2 size={4} sx={{alignContent: "center", justifyContent: "end", display: "grid"}}>
                    <AccountMenu/>
                </Grid2>
            </Grid2>
            </Toolbar>
        </AppBar>
        <Drawer sx={{position: "relative"}} variant="permanent" open={open}>
            <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
                {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
            </DrawerHeader>
            <Divider />
            <List>
            {NAVIGATION.map((OPTION, index) => (
                <ListItem key={OPTION.title} disablePadding 
                    sx={{ 
                        display: 'block', 
                        "& span":{fontSize: fontSize},
                        "& svg":{fontSize: iconSize}
                    }}>
                <ListItemButton
                    onClick={e=>{
                        if(OPTION?.route){ navigate(OPTION.route) }
                        if(OPTION?.action){
                            const action_click = OPTION.action;
                            action_click()
                        }

                    }}
                    sx={[
                    {
                        minHeight: 48,
                        px: 2.5,
                    },
                    open
                        ? {
                            justifyContent: 'initial',
                        }
                        : {
                            justifyContent: 'center',
                        },
                    ]}
                >
                    <ListItemIcon
                    sx={[
                        {
                        minWidth: 0,
                        justifyContent: 'center',
                        },
                        open
                        ? {
                            mr: 3,
                            }
                        : {
                            mr: 'auto',
                            },
                    ]}
                    >
                    {OPTION?.icon ? OPTION.icon : <MailIcon />}
                    </ListItemIcon>
                    <ListItemText
                    primary={OPTION.title}
                    sx={[
                        open
                        ? {
                            opacity: 1,
                            }
                        : {
                            opacity: 0,
                            },
                    ]}
                    />
                </ListItemButton>
                </ListItem>
            ))}
            </List>
            {/* <Divider /> */}
            {!open ? (<KeyboardArrowRight onClick={e=>setOpen(!open)} sx={style_open}/>): (<KeyboardArrowLeft onClick={e=>setOpen(!open)} sx={style_open}/>)}
        </Drawer>
        <Box id="main" component="main" sx={{ flexGrow: 1, p: 3 }}>
            <DrawerHeader />
            <Grid2 container sx={{marginBottom: "20px"}}>
              <Grid2 size={6}>
                {title && title!=="" ? (<Typography variant="h5">{title}</Typography>): null}
              </Grid2>
              <Grid2 size={6} sx={{
                display: "grid",
                justifyContent: "end",
                alignContent: "center",
              }}>{acciones}</Grid2>
            </Grid2>
            {children}
        </Box>
      </Box>);
    }else{
      return(<Box>
        <Loading/>
      </Box>)
    }
}
