import { useContext, useEffect, useState } from "react";
import TablaEvaluaciones from "../componentes/evaluaciones/Tabla"
import MenuEstatico from "../elementos/Menu"
import {Box, Button, Modal, Typography} from '@mui/material';
import { Delete, Visibility } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Pacientes } from "../context";
import { Deportes, Sexo } from "../elementos/Catalogo";
import { DecimalYear, formatDate } from "../elementos/formatDate";

const headCells = [
    {
        id: 'id',
        label: 'id',
    },
    {
      id: 's_name',
      numeric: false,
      disablePadding: true,
      label: 'Nombre',
    },
    {
      id: 's_deporte',
      numeric: true,
      disablePadding: false,
      label: 'Deporte',
    },
    {
      id: 's_sexo',
      numeric: true,
      disablePadding: false,
      label: 'Sexo',
    },
    {
      id: 'd_fecha_nacimiento',
      numeric: true,
      disablePadding: false,
      label: 'Fecha de Nacimiento',
    },
    {
      id: 'd_fecha_evaluacion',
      numeric: true,
      disablePadding: false,
      label: 'Fecha de evaluación',
    },
    {
        id: 'n_edad',
        numeric: true,
        disablePadding: false,
        label: 'Edad',
    },
    {
        id: 'ac_acciones',
        numeric: true,
        disablePadding: false,
        label: 'Acciones',
        align: "right"
    }
];

const createData = (id, s_name, s_deporte, s_sexo, d_fecha_nacimiento, d_fecha_evaluacion, n_edad, ac_acciones) => {
    return {id, s_name, s_deporte, s_sexo, d_fecha_nacimiento, d_fecha_evaluacion, n_edad, ac_acciones};
}

const Evaluaciones = () => {
    let [evaluaciones, setEvaluaciones] = useState([]);
    const PacientesContext = useContext(Pacientes);
    const { obtener_pacientes, pacientes, seleccionar_paciente, eliminar_paciente } = PacientesContext;
    const [open, setOpen] = useState(false)
    const [height, setHeight] = useState(0)
    const [id_select, setId_select] = useState(null)
    const navigate = useNavigate()

    const cargar_informacion = async (pacientes=[]) => {
        setEvaluaciones(pacientes.map((paciente, id_paciente) => {
            return createData(
                id_paciente+1,
                paciente?.s_nombre ? paciente.s_nombre: "",
                Deportes(paciente?.n_deporte ? paciente.n_deporte: 101),
                Sexo(paciente?.n_sexo ? paciente.n_sexo: 101),
                formatDate(paciente?.d_nacimiento ? paciente.d_nacimiento: Date.now()),
                formatDate(paciente?.dt_created ? paciente.dt_created: Date.now()),
                DecimalYear(paciente.dt_created, paciente.d_nacimiento),
                (<Box>
                    <Visibility onClick={e=>{
                        navigate(`ver/${paciente._id}`)
                        seleccionar_paciente(paciente._id, paciente)
                    }} sx={{marginRight: "6px", fontSize: "15px", cursor: "pointer"}}/>
                    <Delete onClick={e=>{
                        setOpen(true)
                        setId_select(paciente._id)
                    }} sx={{marginRight: "6px", fontSize: "15px", cursor: "pointer"}}/>

                </Box>)
            )
        }))
    }

    useEffect(()=>{
        obtener_pacientes()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        cargar_informacion(pacientes)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[pacientes])

    const elem = document.getElementById("main");

    useEffect(()=>{
        if(elem?.offsetHeight){ setHeight(elem.offsetHeight)}
    },[elem])

    return(<MenuEstatico title="Evaluaciones" acciones={(<Box>
        <Modal open={open}>
            <Box sx={style}>
                <Typography>¿Desea eliminar la evaluación?</Typography>
                <Typography sx={{fontSize: "10px"}}>El registro de evaluación se eliminara una vez que confirme.</Typography>
                <Box sx={{display: "flex", marginTop: "20px"}}>
                    <Button onClick={e=>{
                        if(id_select){
                            eliminar_paciente(id_select)
                            setOpen(false)
                        }
                        setId_select(null)
                    }} variant="contained" sx={{
                        textTransform: "none",
                        borderRadius: "20px",
                        width: "100%",
                        marginRight: "10px",
                        background: "rgba(255,144,39,0.9192927170868347)",
                        "&:hover":{ background: "rgba(255,177,104,1)"}
                    }}>Confirmar</Button>
                    <Button onClick={e=>{
                        setOpen(false)
                        setId_select(null)
                    }} variant="contained" sx={{
                            textTransform: "none",
                            borderRadius: "20px",
                            marginLeft: "10px",
                            width: "100%",
                            background: "rgba(255,144,39,0.9192927170868347)",
                            "&:hover":{
                                background: "rgba(255,177,104,1)"
                            }
                        }}>Cancelar</Button>
                </Box>
            </Box>
        </Modal>
        <Button onClick={e=> {
            navigate("/evaluaciones/crear")
        }} variant="filled" sx={{
            background: "#f47432",
            fontSize: "12px",
            textTransform: "none",
            color: "white",
            fontWeight: "bold"
        }}>Nueva Evaluación</Button>
    </Box>)}>
    <Box>
        <TablaEvaluaciones height={height} rows={evaluaciones} headCells={headCells}/>
    </Box>
    </MenuEstatico>)
}

export default Evaluaciones

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: "8px",
    border: "none",
    p: "15px 20px",
  };
