import { Alert, Box, Button, Grid2, MenuItem, Stack, Typography } from "@mui/material";
import MenuEstatico from "../elementos/Menu";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReferenceLine from "../componentes/nueva_evaluacion/Graficas";
import { Campos } from "../elementos/Campos";
import { Print } from "@mui/icons-material";
import { Selector } from "../elementos/Selector";
import { useContext, useEffect, useRef, useState } from "react";
import { Pacientes } from "../context";
import { DecimalYear } from "../elementos/formatDate";
import { est_talla_adulto, useContainerDimensions } from "../elementos/Catalogo";
import { ExportarDocumento } from "../componentes/nueva_evaluacion/ExportarDocumento";
import Grafica2 from "../componentes/nueva_evaluacion/Grafica2";

const h_sx = {
    borderLeft: "10px solid #f47432",
    paddingLeft: "15px",
    fontWeight: "bold"   
}

const NuevaEvaluacion = () => {
    const navigate = useNavigate();
    const PacientesContext              = useContext(Pacientes);
    const { crear_paciente }            = PacientesContext;
    const [edad, setEdad]               = useState(0)
    const [remitente, setRemitente] = useState("Dra. Ivonne Azeret Estrada Sánchez")
    const [edadPico, setEdadPico]       = useState(0)
    const [observaciones, setObservaciones] = useState("")
    const [falta, setFalta]             = useState(0)
    const [indice_mad, setIndice_mad]   = useState(0)
    const [alert, setAlert]             = useState(false)
    const contentRef = useRef();
    
    const [form, setForm] = useState({
        s_nombre: "",
        n_deporte: 0,
        n_peso: 0,
        n_talla: 0,
        n_tsent: 0,
        n_estAdultEst: 0,
        n_edadPico: 0,
        n_edadEst: 0,
        n_longPiernas: 0,
        n_indiceCormico: 0,
        n_imc: 0,
        n_peso_df: 0,
        n_indiceMad: 0,
        n_clase: 0,
        d_nacimiento: 0,
        n_sexo: 0,
        s_observaciones: "",
        dt_created: Date.now(),
        dt_modificated: Date.now()
    })

    useEffect(()=>{
        if(alert){
            setTimeout(()=>{
                setAlert(null)
            }, 3000)
        }
    },[alert])
    useEffect(()=>{
        let edad_est = calcular_edad_est(form.n_sexo, edadPico)
        setIndice_mad(calcular_indice_mad())
        setForm({
            ...form,
            n_clase: definir_clase(form.n_sexo),
            n_edadEst: calcular_edad_est(form.n_sexo, edadPico),
            n_edadPico: edadPico,
            n_edadEst: edad_est,
            n_falta: falta,
            n_estAdultEst: falta+form.n_talla,
            n_clase: definir_clase(form.n_sexo),
            n_edadEst: calcular_edad_est(form.n_sexo, edadPico),
            n_edadPico: edadPico,
        })
    },[form.n_sexo, form.n_peso, form.n_talla, form.n_tsent, edadPico, falta, form.d_nacimiento])

    useEffect(()=>{
        setEdadPico(parseFloat(parseFloat(edad-indice_mad).toFixed(2)))
        setForm({...form, n_indiceMad: indice_mad})
        calculat_falta_cm(form.n_sexo, indice_mad, form.n_clase)
    },[indice_mad, form.n_clase])

    const handleChange = (e, name) => {
        let prop_name = name ? name: e?.target?.name ? e.target.name: null;
        
        if(prop_name){
            setForm({
                ...form,
                [prop_name]: e.target.value
            })
        }
    }

    const handleChangeNo = (e, name) => {
        let prop_name = name ? name: e?.target?.name ? e.target.name: null;
        
        if(prop_name){
            setForm({
                ...form,
                [prop_name]: parseInt(e.target.value)
            })
        }
    }
    

    const calcular_edad_est = (sexo, edad_pico) =>{
        // TEMP = 1, NORM = 2, TARD = 3
        let edad_est = 0;
        if(sexo===1){
            if(form.n_clase === 1 || form.n_clase===2){
                edad_est = edad_pico + 4
            }else{
                edad_est = edad_pico + 3
            }
        }else{
            edad_est = edad_pico + 4
        }

        return edad_est;
    }
    const definir_clase = (sexo = 1) => {
        // TEMP = 1, NORM = 2, TARD = 3
        // SEXO; HOMBRE = 1, MUJER = 2
        let validacion = sexo === 1 ? 
                edadPico < 13 ? 1: 
                edadPico < 15 ? 2:3
            :sexo === 2 ? 
                edadPico < 11 ? 1:
                edadPico < 13 ? 2:3:
            4;
        return validacion
    }

    const calcular_indice_mad = () => {
        const sexo      = form?.n_sexo          ? form.n_sexo:          0, 
        long_piernas    = form?.n_longPiernas   ? form.n_longPiernas:   0;
        const edad_     = edad                  ? edad:                 0, 
        tsent           = form?.n_tsent         ? form.n_tsent:         0,
        peso            = form?.n_peso          ? form.n_peso:          0,
        talla           = form?.n_talla         ? form.n_talla:         0
        
        let mad = 0
        if(sexo===1){
            let cal_1 = parseFloat(parseFloat(0.0002708*long_piernas*tsent).toFixed(5)),
            cal_2 = parseFloat(parseFloat(0.001663*edad_*long_piernas).toFixed(5)),
            cal_3 = parseFloat(parseFloat(0.007216*edad_*tsent).toFixed(5)),
            cal_4 = parseFloat(parseFloat(0.02292*peso/(talla/100)).toFixed(5))

            mad = -9.236 + cal_1 - cal_2 + cal_3 + cal_4

        }else if(sexo===2){
            let cal_1 = parseFloat(parseFloat(0.0001882*long_piernas*tsent).toFixed(5)),
            cal_2 = parseFloat(parseFloat(0.0022*edad_*long_piernas).toFixed(5)),
            cal_3 = parseFloat(parseFloat(0.005841*edad_*tsent).toFixed(5)),
            cal_4 = parseFloat(parseFloat(0.002658*edad_*peso).toFixed(5)),
            cal_5 = parseFloat(parseFloat(0.07693*peso/(talla/100)).toFixed(5))

            mad = -9.376 + cal_1 + cal_2 + cal_3 - cal_4 + cal_5
        }else{
            mad = "ERROR SEXO";
        }

        return mad && mad != "ERROR SEXO" ? mad: 0
    }

    const calculat_falta_cm = async (n_sexo, indiceMad, n_clase) => {
        let falta_cm = await est_talla_adulto(n_sexo, indiceMad, n_clase), falta_val = 0
        // TEMP = 1, NORM = 2, TARD = 3


        if(falta_cm?.length > 0){
            switch(n_clase){
                case 1: 
                    falta_val = falta_cm[0]?.temp ? falta_cm[0].temp: 0
                    break;
                case 2: 
                    falta_val = falta_cm[0]?.norm ? falta_cm[0].norm: 0
                    break;
                case 3: 
                    falta_val = falta_cm[0]?.tard ? falta_cm[0].tard: 0
                    break;
                default: 
                    falta_val = falta_cm[0]?.tard ? falta_cm[0].tard: 0
                    break;
            }
        }

        await setFalta(falta_val)
        return;
    }

    const refGrid = useRef(null);
    const { width } = useContainerDimensions(refGrid)

    const validacion_formulario = () => {
        if(!form?.s_nombre){
            setAlert("Error. Falta el nombre del paciente")
            return false}
        if(!form?.d_nacimiento){
            setAlert("Error. Falta fecha de nacimiento del paciente")
            return false}
        if(!form?.n_sexo){
            setAlert("Error. Falta el sexo del paciente")
            return false}
        // if(!form?.n_deporte){
        //     setAlert("Error. Falta el deporte del paciente")
        //     return false}
        if(!form?.n_peso){
            setAlert("Error. Falta el peso del paciente")
            return false}
        if(!form?.n_talla){
            setAlert("Error. Falta el peso del paciente")
            return false}
        if(!form?.n_tsent){
            setAlert("Error. Falta el peso del paciente")
            return false}
        
        return true
    }

    return(<MenuEstatico title="Nueva Evaluación" acciones={(<Box sx={{display: "flex"}}>
        <Button onClick={e=> {
            navigate("/evaluaciones")
        }} variant="filled" sx={{
            background: "#f47432",
            fontSize: "12px",
            textTransform: "none",
            color: "white",
            fontWeight: "bold"
        }}><ArrowBackIcon sx={{fontSize: "14px", marginRight: "10px"}}/>Regresar</Button>
        <ExportarDocumento
            contentRef={contentRef}
            validacion={validacion_formulario}
            data={{
                nombre: form?.s_nombre ? form.s_nombre: "",
                remitente: remitente,
                indice_mad: form?.n_indiceMad ? parseFloat(form.n_indiceMad).toFixed(2): 0,
                clase: form?.n_clase? form.n_clase: 0,
                titulo: "Dr. Ciencias | Master Nut. Deportiva",
                edad_est: form?.n_edadEst ? form.n_edadEst: 0,
                est_estimada: form?.n_estAdultEst ? form.n_estAdultEst: 0,
                edad_pico: form?.n_edadPico ? form.n_edadPico: 0,
                edad_nac: DecimalYear(form?.dt_created ? form.dt_created: 0, form?.d_nacimiento ? form.d_nacimiento: 0),
                observaciones: form?.s_observaciones ? form.s_observaciones: null
            }}
        />
        <Button onClick={e=> {
            if(!form?.s_nombre){        return setAlert("Error. Falta el nombre del paciente")}
            if(!form?.d_nacimiento){    return setAlert("Error. Falta fecha de nacimiento del paciente")}
            if(!form?.n_sexo){          return setAlert("Error. Falta el sexo del paciente")}
            if(!form?.n_deporte){       return setAlert("Error. Falta el deporte del paciente")}
            if(!form?.n_peso){          return setAlert("Error. Falta el peso del paciente")}
            if(!form?.n_talla){          return setAlert("Error. Falta el peso del paciente")}
            if(!form?.n_tsent){          return setAlert("Error. Falta el peso del paciente")}
            
            let success = crear_paciente(form)
            if(success){
                navigate("/evaluaciones")
            }
        }} variant="filled"
            sx={{
                background: "#f47432",
                fontSize: "12px",
                textTransform: "none",
                color: "white",
                fontWeight: "bold",
                marginLeft: "10px"
        }}>Guardar</Button>
    </Box>)}>
        <Grid2 container>
            <Grid2 ref={refGrid} id="grid-container"  size={{xs: 12, md: 12, xl: 6, lg: 12}}>
                <Box ref={contentRef}>
                    <Grafica2
                        edadEst={form?.n_edadEst ? parseFloat(form.n_edadEst).toFixed(2): 0}
                        width={width}
                        sexo={form?.n_sexo ? form.n_sexo: 1}
                        edadPico={edadPico}
                        nombre={form?.s_nombre ? form.s_nombre: null}
                    />
                </Box>
                <Box sx={{paddingX: "50px"}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={8}>
                            <Typography sx={{fontSize: "14px"}}>Nombre</Typography>
                            <Campos name="s_nombre" onChange={handleChange} align="left"/>
                        </Grid2>
                        <Grid2 size={4}>
                            <Typography sx={{fontSize: "14px"}}>Fecha de Nacimiento</Typography>
                            <Campos onChange={e=> {
                                var someDate = new Date(e.target.value);
                                someDate = someDate.getTime() + (12*60*60*1000);

                                setEdad(DecimalYear(Date.now(), someDate))
                                
                                setForm({
                                    ...form,
                                    d_nacimiento: someDate
                                })
                            }} type="date"/>
                        </Grid2>
                    </Grid2>
                </Box>
                <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={4}>
                            <Typography sx={{fontSize: "14px"}}>Sexo</Typography>
                            <Selector value={form?.n_sexo ? form.n_sexo: 101} onChange={e=>{
                                calculat_falta_cm(e.target.value, indice_mad, form.n_clase)
                                setForm({
                                    ...form,
                                    n_sexo: e.target.value
                                })
                                
                            }}>
                                <MenuItem value={101}><em>Seleccione el sexo</em></MenuItem>
                                <MenuItem value={1}>Masculino</MenuItem>
                                <MenuItem value={2}>Femenino</MenuItem>
                            </Selector>
                        </Grid2>
                        <Grid2 size={8}>
                            <Typography sx={{fontSize: "14px"}}>Deporte</Typography>
                            <Selector onChange={e => handleChangeNo(e, "n_deporte")} value={form?.n_deporte ? form.n_deporte: 101}>
                                <MenuItem value={101}><em>Seleccione un deporte</em></MenuItem>
                                <MenuItem value={1}>Fútbol</MenuItem>
                                <MenuItem value={2}>Básquetbol</MenuItem> 
                                <MenuItem value={3}>Natación</MenuItem> 
                                <MenuItem value={4}>Atletismo</MenuItem>
                                <MenuItem value={5}>Taekwondo</MenuItem>
                                <MenuItem value={6}>Karate</MenuItem>
                                <MenuItem value={7}>Rugby</MenuItem>
                                <MenuItem value={8}>Otro</MenuItem>
                            </Selector>
                        </Grid2>
                    </Grid2>
                </Box>
            </Grid2>
            <Grid2 sx={{marginTop: "50px"}} size={{xs: 12, md: 12, xl: 6, lg: 12}}>
                <Box sx={{overflowY: "scroll", height: "70vh"}}>
                    <Typography sx={h_sx}>Datos Básicos</Typography>
                    <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Peso (kg)</Typography>
                            <Campos type="number" name="n_peso" onChange={e=>{
                                let talla = form?.n_talla ? form.n_talla: 0,
                                imc = parseFloat(e.target.value / (Math.pow((talla/100),2))).toFixed(2)
                                if(imc == Infinity){
                                    imc = 0
                                }
                                setForm({
                                    ...form,
                                    n_peso: parseFloat(e.target.value),
                                    n_imc: parseFloat(imc)
                                })
                            }} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Talla (cm)</Typography>
                            <Campos type="number" name="n_talla" onChange={e=>{
                                let tsent = form?.n_tsent ? form.n_tsent: 0
                                let total = tsent/parseFloat(e.target.value).toFixed(2)
                                let peso = form?.n_peso ? form.n_peso: 0,
                                imc = parseFloat(peso / Math.pow(parseFloat(e.target.value)/100, 2)).toFixed(2)

                                setForm({
                                    ...form,
                                    n_talla: parseFloat(parseFloat(e.target.value).toFixed(2)),
                                    n_longPiernas: parseFloat(parseFloat(e.target.value).toFixed(2)-tsent),
                                    n_indiceCormico: parseFloat(parseFloat(total*100).toFixed(2)),
                                    n_imc: parseFloat(imc)
                                })
                            }} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Tsent (cm)</Typography>
                            <Campos type="number" name="n_tsent" onChange={e=>{
                                let talla = form?.n_talla ? form.n_talla: 0
                                let total = parseFloat(e.target.value).toFixed(2)/talla;
                                setForm({
                                    ...form,
                                    n_tsent: parseFloat(parseFloat(e.target.value).toFixed(2)),
                                    n_longPiernas: talla - parseFloat(e.target.value).toFixed(2),
                                    n_indiceCormico: parseFloat(parseFloat(total*100).toFixed(2))
                                })
                            }} align="left"/>
                        </Box>
                    </Box>
                    <Typography sx={h_sx}>Estimaciones</Typography>
                    <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Clase</Typography>
                            <Campos disabled={true} value={form?.n_clase == 1 ? "TEMP": form.n_clase == 2 ? "NORM": "TARD"} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Falta (cm)</Typography>
                            <Campos disabled={true} name="n_falta" value={falta} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Est adult est</Typography>
                            <Campos disabled={true} name="n_estAdultEst" value={form.n_estAdultEst} align="left"/>
                        </Box>
                        {/* <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Edad Pico</Typography>
                            <Campos name="n_edadPico" onChange={handleChangeNo} align="left"/>
                        </Box> */}
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Edad est</Typography>
                            <Campos name="n_edadEst" disabled={true} value={parseFloat(form.n_edadEst).toFixed(2)} align="left"/>
                        </Box>
                    </Box>
                    <Typography sx={h_sx}>Derivadas de cálculo</Typography>
                    <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Longitud piernas</Typography>
                            <Campos type="number" disabled={true} name="n_longPiernas" value={form.n_longPiernas} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Índice córmico</Typography>
                            <Campos name="n_indiceCormico" value={`${form.n_indiceCormico}%`} disabled={true} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>IMC</Typography>
                            <Campos name="n_imc" disabled={true} value={form.n_imc} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Índice de Maduración</Typography>
                            <Campos name="n_indiceMad" value={parseFloat(parseFloat(indice_mad).toFixed(1))} disabled={true} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Edad Pico</Typography>
                            <Campos name="n_edadPHV" disabled={true} value={edadPico} align="left"/>
                        </Box>
                    </Box>
                    <Typography sx={h_sx}>Observaciones</Typography>
                    <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                        <Box sx={{marginBottom: "20px"}}>
                            <Campos
                                onChange={e => {
                                    setObservaciones(e.target.value)
                                    setForm({...form, s_observaciones: e.target.value})
                                }}
                                name="s_observaciones"
                                rows={5} align="left"
                                multiline={true}
                                value={observaciones}
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid2>
        </Grid2>
        {alert ? (<Stack sx={{ width: '300px', position: "absolute", bottom: 20, right: 20 }} spacing={2}>
            <Alert variant="filled" severity="error">{alert ? alert: "Faltan datos por completar"}</Alert>
        </Stack>): null}
    </MenuEstatico>)
}

export default NuevaEvaluacion