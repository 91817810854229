import { Box, Select } from "@mui/material"

export const Selector = ({children, disabled=false, defaultValue=101, value=101, onChange=e=>{console.log("onChange")}}) => {
    return(<Box sx={{
        "& .MuiSelect-select":{
            height: "30px",
            padding: "5px 0px 0px 15px!important",
            fontSize: "12px !important",
            background: "#e8e8e8"
        }
    }}>
        <Select disabled={disabled} onChange={onChange} value={value} defaultValue={defaultValue} fullWidth sx={{borderRadius: "10px"}}>
            {children}
        </Select>
    </Box>)
}