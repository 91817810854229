import * as React from 'react';
import { ChartContainer } from '@mui/x-charts/ChartContainer';
import { ChartsReferenceLine } from '@mui/x-charts/ChartsReferenceLine';
import { LineChart, LinePlot, MarkPlot } from '@mui/x-charts/LineChart';
import { ChartsXAxis } from '@mui/x-charts/ChartsXAxis';
import { ChartsYAxis } from '@mui/x-charts/ChartsYAxis';
import { ChartsGrid, ResponsiveChartContainer } from '@mui/x-charts';
import { Box, Typography } from '@mui/material';

export default function Grafica2({nombre=null, edadPico = 0, edadEst=0, sexo=1, width = 0}) {
    const [dimension, setDimension] = React.useState(500)
    const elem = document.getElementById("grid-container");
    
    React.useEffect(()=>{
        let setWidth = 0;
        if(width){
            setWidth = width
        }else if(elem?.offsetWidth){
            setWidth = elem.offsetWidth 
        }

        if(setWidth > 630 || setWidth < 610){
            setWidth = 630;
        }

        setDimension(setWidth)
    },[width, elem])

    const [lineM, setLineM] = React.useState([
        {x_line: 0,     y_line: null}, 
        {x_line: 1,     y_line: 13.17}, 
        {x_line: 1.5,   y_line: 11.2}, 
        {x_line: 2,     y_line: 9.13}, 
        {x_line: 2.5,   y_line: 8.24}, 
        {x_line: 3,     y_line: 7.83}, 
        {x_line: 3.5,   y_line: 7.46}, 
        {x_line: 4,     y_line: 7.17}, 
        {x_line: 4.5,   y_line: 6.88}, 
        {x_line: 4.75,  y_line: 6.68}, 
        {x_line: 5,     y_line: 6.53}, 
        {x_line: 5.15,  y_line: 6.43}, 
        {x_line: 5.5,   y_line: 6.28}, 
        {x_line: 5.75,  y_line: 6.18}, 
        {x_line: 6,     y_line: 6.09}, 
        {x_line: 6.25,  y_line: 5.93}, 
        {x_line: 6.5,   y_line: 5.89}, 
        {x_line: 6.60,  y_line: 5.84}, 
        {x_line: 6.75,  y_line: 5.79}, 
        {x_line: 7,     y_line: 5.70},
        {x_line: 7.25,  y_line: 5.64},
        {x_line: 7.5,   y_line: 5.58},
        {x_line: 7.75,  y_line: 5.52},
        {x_line: 8.0,   y_line: 5.46},
        {x_line: 8.25,  y_line: 5.38},
        {x_line: 8.5,   y_line: 5.3},
        {x_line: 9,     y_line: 5.16},
        {x_line: 9.5,   y_line: 4.99},
        {x_line: 10,    y_line: 5.08},
        {x_line: 10.1,  y_line: 5.18},
        {x_line: 10.25, y_line: 5.28},
        {x_line: 10.5,  y_line: 5.51},
        {x_line: 10.6,  y_line: 5.72},
        {x_line: 10.75, y_line: 5.92},
        {x_line: 11,    y_line: 6.45},
        {x_line: 11.5,  y_line: 7.8},
        {x_line: 12,    y_line: 8.99},
        {x_line: 12.5,  y_line: 7},
        {x_line: 13,    y_line: 5},
        {x_line: 14,    y_line: 2.15},
        {x_line: 14.75,    y_line: 0.8},
        {x_line: 15,    y_line: 0.72},
        {x_line: 15.1,  y_line: 0.68},
        {x_line: 16,    y_line: null},
        {x_line: 16.5,  y_line: null},
        {x_line: 17,    y_line: null},
        {x_line: 17.5,  y_line: null},
        {x_line: 18,    y_line: null},
        {x_line: 18.5,  y_line: null},
        {x_line: 19,    y_line: null},
        {x_line: 19.5,  y_line: null},
        {x_line: 20,    y_line: null}
    ])

    const [lineH, setLineH] = React.useState([
        {x_line: 0,     y_line: null}, 
        {x_line: 1,     y_line: 13.17}, 
        {x_line: 1.5,   y_line: 11.2}, 
        {x_line: 2,     y_line: 9.13}, 
        {x_line: 2.5,   y_line: 8.24}, 
        {x_line: 3,     y_line: 7.83}, 
        {x_line: 3.5,   y_line: 7.46}, 
        {x_line: 4,     y_line: 7.17}, 
        {x_line: 4.5,   y_line: 6.88}, 
        {x_line: 4.75,  y_line: 6.68}, 
        {x_line: 5,     y_line: 6.53}, 
        {x_line: 5.15,  y_line: 6.43}, 
        {x_line: 5.5,   y_line: 6.28}, 
        {x_line: 5.75,  y_line: 6.18}, 
        {x_line: 6,     y_line: 6.09}, 
        {x_line: 6.25,  y_line: 5.93}, 
        {x_line: 6.5,   y_line: 5.89}, 
        {x_line: 6.60,  y_line: 5.84}, 
        {x_line: 6.75,  y_line: 5.79}, 
        {x_line: 7,     y_line: 5.70},
        {x_line: 7.25,  y_line: 5.64},
        {x_line: 7.5,   y_line: 5.58},
        {x_line: 7.75,  y_line: 5.52},
        {x_line: 8.0,   y_line: 5.46},
        {x_line: 8.25,  y_line: 5.38},
        {x_line: 8.5,   y_line: 5.3},
        {x_line: 9,     y_line: 5.16},
        {x_line: 9.5,   y_line: 4.99},
        {x_line: 10,    y_line: 4.88},
        {x_line: 10.5,  y_line: 4.78},
        {x_line: 10.75, y_line: 4.73},
        {x_line: 11,    y_line: 4.72},
        {x_line: 11.25, y_line: 4.70},
        {x_line: 11.5,  y_line: 4.74},
        {x_line: 11.60, y_line: 4.71},
        {x_line: 11.75, y_line: 4.69},
        {x_line: 12,    y_line: 4.71},
        {x_line: 12.25, y_line: 4.75},
        {x_line: 12.5,  y_line: 4.99},
        {x_line: 12.75, y_line: 5.43},
        {x_line: 12.8,  y_line: 5.6},
        {x_line: 12.9,  y_line: 6},
        {x_line: 13,    y_line: 6.32},
        {x_line: 14,    y_line: 10.18},
        {x_line: 14.5,  y_line: 8},
        {x_line: 15,    y_line: 5.34},
        {x_line: 15.5,  y_line: 3.52},
        {x_line: 16,    y_line: 2.19},
        {x_line: 16.5,  y_line: 1.49},
        {x_line: 17,    y_line: 1.06},
        {x_line: 17.5,  y_line: 0.67},
        {x_line: 18,    y_line: 0.46},
        {x_line: 18.5,  y_line: null},
        {x_line: 19,    y_line: null},
        {x_line: 19.5,  y_line: null},
        {x_line: 20,    y_line: null}
    ])

  return (<Box sx={{position: "relative"}}>
    <Box sx={{position: "absolute", top: 0, textAlign: "center", width: "100%"}}>
        <Typography sx={{fontWeight:"bold"}}>Velocidad de crecimiento ({sexo === 1 ? "Hombres": "Mujeres"})</Typography>
    </Box>
    <ChartContainer
        dataset={sexo === 1 ? lineH: lineM}
        disableAxisListener={true}
        height={400}
        
        width={dimension}
            series={[{
                type: "line",
                color: "#f47432",
                dataKey: "y_line",
            }]}
        xAxis={[{
            label: 'años',
            id: 'años',
            dataKey: 'x_line',
            scaleType: 'linear',
        }]}
        yAxis={[{
            id: 'cm',
            dataKey: "y_line",
            label: 'centimetros por año (cm)',
        }]}
    >
        <ChartsReferenceLine
            x={edadPico}
            textAlign={"center"}
            lineStyle={{ strokeDasharray: '10 5' }}
            labelStyle={{ fontSize: '10' }}
            label={`${nombre?`${nombre} \n`:""}edad Pico: ${edadPico}`}
            labelAlign={"start"}
        />

        {edadEst ? (<ChartsReferenceLine
            x={edadEst}
            color="red"
            lineStyle={{
                border: "1px red solid"
                // strokeDasharray: '10 5'
            }}
            labelStyle={{ fontSize: '10' }}
            label={`${nombre?`${nombre} \n`:""}edad est: ${edadEst}`}
        />): null}
        <LinePlot/>
        <ChartsXAxis/>
        <ChartsGrid horizontal={true} vertical={true}/>
        <ChartsYAxis />
    </ChartContainer>
  </Box>);
}