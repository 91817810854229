import { Alert, Box, Button, Grid2, Stack, Typography } from "@mui/material";
import imgsrc from '../images/logo_banner.png'
import imgbkg1 from '../images/bkg_4.jpg'
import { Campos } from "../elementos/Campos";
import {Auth} from '../context';
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

const Login = () => {
    const authContext = useContext(Auth);
    const { login, mensaje, usuario, setToken, token, usuarioAutenticado} = authContext;
    const navigate = useNavigate();
    const [timeError, setTimeError] = useState(false)
    const [form, setForm] = useState({
        s_user: "",
        s_password: ""
    })

    useEffect(()=>{
        let save_token = localStorage.getItem("token");
        if(save_token){
            setToken(save_token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(token){
            usuarioAutenticado(token)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[token])

    useEffect(()=>{
        if(mensaje){
            setTimeError(true)
            setTimeout(()=>{setTimeError(false)}, 3000)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[mensaje])

    useEffect(()=>{
        if(usuario && usuario !== "error"){ navigate("/dashboard")}
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },[usuario])

    const onSubmit = async data => {
        login(data)
    }

    const KeyUp = e => {
        if(e.key === "Enter"){
            login(form)
        }
    }

    const handleChange = e => {
        setForm({
            ...form,
            [e.target.name]: e.target.value
        })
    }
    return(<Box sx={{
        height: "100vh",
        background: "#e8e8e8",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative"
    }}>
        <Grid2 container sx={{
            height: "100vh",
            width: "70%",
            maxWidth: "700px",
            maxHeight: "500px",
            background: "white",
            borderRadius: "8px",
            boxShadow: "0px 1px 28px -19px black"
        }}>
            <Grid2 sx={{position: "relative"}} size={{xs: 12, sm: 12, md: 6, lg: 5}}>
                <Box sx={{display: "grid", justifyContent: "center", alignContent: "center", margin: "20px"}}>
                    <img style={{ height: "50px", justifySelf: "center", marginBottom: "20px", marginTop: "20px"}} src={imgsrc} alt="tu nutrióloga personal"/>
                    {/* <Typography variant="p" sx={{
                        fontSize: "18px",
                        fontWeight: "bold"
                    }}>Tu Nutrióloga Personal</Typography> */}

                </Box>
                <Box sx={{margin: "30px"}}>
                    <Typography sx={{textAlign: "start", fontSize: "12px"}}>USER</Typography>
                    <Campos onKeyDown={KeyUp} name="s_user" onChange={handleChange} sx={{marginBottom: "20px"}}/>
                    <Typography sx={{textAlign: "start", fontSize: "12px"}}>PASSWORD</Typography>
                    <Campos onKeyDown={KeyUp} name="s_password" onChange={handleChange} type="password" sx={{marginBottom: "20px"}}/>
                    <Box sx={{
                        position: "absolute",
                        bottom: "20px",
                        paddingX:"20px",
                        left: "0px",
                        width: "-webkit-fill-available"
                    }}>
                        <Button onClick={e=>{
                            onSubmit(form)
                            // navigate("/dashboard")
                        }} variant="contained" sx={{
                            textTransform: "none",
                            borderRadius: "20px",
                            width: "100%",
                            background: "rgba(255,144,39,0.9192927170868347)",
                            "&:hover":{
                                background: "rgba(255,177,104,1)"
                            }
                        }}>iniciar sesión</Button>
                    </Box>
                </Box>
            </Grid2>
            <Grid2 className="login-grid-2" size={{xs:12, sm: 12, md:6, lg: 7}} sx={{
                position: "relative",
                alignContent: "center",
                justifyContent: "center",
                justifyItems: "center",
                backgroundImage: `url("${imgbkg1}")`,
                backgroundSize:     "cover",
                backgroundRepeat:   "no-repeat",
                backgroundPosition: "center center"
            }}>
                <Box className="img-orange-login" sx={{
                    width: "100%",
                    background: "rgba(255,144,39,0.9192927170868347)",
                    height: "-webkit-fill-available",
                    opacity: 0.6,
                }}>
                </Box>
                <Box className="img-orange-login"
                    sx={{
                        width: "100%",
                        height: "-webkit-fill-available",
                        position: "absolute",
                        display: "grid",
                        alignContent: "center",
                        justifyContent: "center",
                        top: 0
                }}>
                    <Typography sx={{
                        fontSize: "16px",
                        padding: "0px 30px",
                        textTransform: "uppercase",
                        fontWeight: "bold",
                        color: "white",
                        textAlign: "center",
                        fontFamily: "conthrax",
                    }}>
                        Nutrición Deportiva - Salud - Rendimiento
                    </Typography>
                </Box>
            </Grid2>
        </Grid2>

        {timeError ? (<Stack sx={{ width: '300px', position: "absolute", bottom: 20, left: 20 }} spacing={2}>
            <Alert variant="filled" severity="error">{mensaje?.msg ? mensaje.msg: "error"}</Alert>
        </Stack>): null}
    </Box>)
}

export default Login;