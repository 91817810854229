/* eslint-disable import/no-anonymous-default-export */
import {
    OBTENER_PACIENTES,
    SELECCIONAR_PACIENTE
} from '../../types'

export default (state, action) => {
    switch(action.type){
        case OBTENER_PACIENTES:
            return{
                ...state,
                pacientes: action.payload,
            }
        case SELECCIONAR_PACIENTE:
            return{
                ...state,
                paciente: action.payload,
            }
        default:
            return state;
    }
}