import { useEffect, useState } from "react"

export const Deportes = id => {
    switch(id){
        case 1:
            return "Fútbol"
        case 2:
            return "Básquetbol"
        case 3:
            return "Natación"
        case 4:
            return "Atletismo"
        case 5:
            return "Taekwondo"
        case 6:
            return "Karate"
        case 7:
            return "Rugby"
        case 8:
            return "Otro"
        default:
            return "sin definir"
    }
}

export const Meses = id => {
    switch(id){
        case "01":
            return "Ene"
        case "02":
            return "Feb"
        case "03":
            return "Mar"
        case "04":
            return "Abr"
        case "05":
            return "May"
        case "06":
            return "Jun"
        case "07":
            return "Jul"
        case "08":
            return "Ago"
        case "09":
            return "Sep"
        case "10":
            return "Oct"
        case "11":
            return "Nov"
        default:
            return "Dic"
    }
}

export const Sexo = id => {
    if(id === 1){
        return "Masculino"
    }else{
        return "Femenino"
    }

    return "sin definir"
} 

export const est_talla_adulto = async (sexo=1, indice_mad) => {
    const hombres = [
        { id: 0,    temp: 45.29,    norm: 40.09,    tard: 34.73,    z: -4},
        { id: 1,    temp: 44.21,    norm: 39.08,    tard: 33.83,    z: -3.80},
        { id: 2,    temp: 43.11,    norm: 38.07,    tard: 32.94,    z: -3.60},
        { id: 3,    temp: 41.99,    norm: 37.06,    tard: 32.05,    z: -3.40},
        { id: 4,    temp: 40.85,    norm: 36.05,    tard: 31.16,    z: -3.20},
        { id: 5,    temp: 39.69,    norm: 35.04,    tard: 30.27,    z: -3},
        { id: 6,    temp: 38.52,    norm: 34.04,    tard: 29.38,    z: -2.80},
        { id: 7,    temp: 37.33,    norm: 33.05,    tard: 28.49,    z: -2.60},
        { id: 8,    temp: 36.15,    norm: 32.06,    tard: 27.6,     z: -2.40},
        { id: 9,    temp: 34.97,    norm: 31.07,    tard: 26.7,     z: -2.20},
        { id: 10,   temp: 33.8,     norm: 30.06,    tard: 25.77,    z: -2},
        { id: 11,   temp: 32.62,    norm: 29.03,    tard: 24.79,    z: -1.80},
        { id: 12,   temp: 31.44,    norm: 27.95,    tard: 23.74,    z: -1.60},
        { id: 13,   temp: 30.23,    norm: 26.83,    tard: 22.63,    z: -1.40},
        { id: 14,   temp: 28.98,    norm: 25.63,    tard: 21.45,    z: -1.20},
        { id: 15,   temp: 27.66,    norm: 24.36,    tard: 20.22,    z: -1},
        { id: 16,   temp: 26.24,    norm: 22.99,    tard: 18.96,    z: -0.80},
        { id: 17,   temp: 24.68,    norm: 21.51,    tard: 17.68,    z: -0.60},
        { id: 18,   temp: 22.96,    norm: 19.88,    tard: 16.31,    z: -0.40},
        { id: 19,   temp: 21.07,    norm: 18.09,    tard: 14.76,    z: -0.20},
        { id: 20,   temp: 19.04,    norm: 16.16,    tard: 13.05,    z: 0},
        { id: 21,   temp: 16.96,    norm: 14.21,    tard: 11.32,    z: 0.20},
        { id: 22,   temp: 14.92,    norm: 12.35,    tard: 9.71,     z: 0.40},
        { id: 23,   temp: 13.01,    norm: 10.65,    tard: 8.27,     z: 0.60},
        { id: 24,   temp: 11.26,    norm: 9.12,     tard: 6.94,     z: 0.80},
        { id: 25,   temp: 9.7,      norm: 7.78,     tard: 5.7,      z: 1},
        { id: 26,   temp: 8.33,     norm: 6.59,     tard: 4.54,     z: 1.20},
        { id: 27,   temp: 7.11,     norm: 5.54,     tard: 3.51,     z: 1.40},
        { id: 28,   temp: 6.04,     norm: 4.62,     tard: 2.64,     z: 1.60},
        { id: 29,   temp: 5.1,      norm: 3.8,      tard: 1.92,     z: 1.80},
        { id: 30,   temp: 4.26,     norm: 3.09,     tard: 1.35,     z: 2},
        { id: 31,   temp: 3.52,     norm: 2.48,     tard: 0.91,     z: 2.20},
        { id: 32,   temp: 2.86,     norm: 1.96,     tard: 0.58,     z: 2.40},
        { id: 33,   temp: 2.29,     norm: 1.52,     tard: 0.32,     z: 2.60},
        { id: 34,   temp: 1.78,     norm: 1.16,     tard: 0.13,     z: 2.80},
        { id: 35,   temp: 1.34,     norm: 0.87,     tard: 0,        z: 3},
        { id: 36,   temp: 0.96,     norm: 0.63,     tard: 0,        z: 3.20},
        { id: 37,   temp: 0.64,     norm: 0.43,     tard: 0,        z: 3.40},
        { id: 38,   temp: 0.37,     norm: 0.27,     tard: 0,        z: 3.60},
        { id: 39,   temp: 0.16,     norm: 0.12,     tard: 0,        z: 3.80},
        { id: 40,   temp: 0,        norm: 0,        tard: 0,        z: 4}
    ]

    const mujeres = [
        { id: 0,  temp: 42.61,  norm: 38.81,    tard: 34.35,    z: -4   },
        { id: 1,  temp: 41.49,  norm: 37.67,    tard: 33.27,    z: -3.80},
        { id: 2,  temp: 40.39,  norm: 36.55,    tard: 32.2,     z: -3.60},
        { id: 3,  temp: 39.3,   norm: 35.44,    tard: 31.14,    z: -3.40},
        { id: 4,  temp: 38.21,  norm: 34.34,    tard: 30.09,    z: -3.20},
        { id: 5,  temp: 37.13,  norm: 33.25,    tard: 29.04,    z: -3   },
        { id: 6,  temp: 36.04,  norm: 32.16,    tard: 27.99,    z: -2.80},
        { id: 7,  temp: 34.94,  norm: 31.04,    tard: 26.93,    z: -2.60},
        { id: 8,  temp: 33.82,  norm: 29.91,    tard: 25.87,    z: -2.40},
        { id: 9,  temp: 32.68,  norm: 28.76,    tard: 24.79,    z: -2.20},
        { id: 10, temp: 31.53,  norm: 27.58,    tard: 23.71,    z: -2   },
        { id: 11, temp: 30.44,  norm: 26.39,    tard: 22.63,    z: -1.80},
        { id: 12, temp: 29.36,  norm: 25.21,    tard: 21.55,    z: -1.60},
        { id: 13, temp: 28.24,  norm: 24.03,    tard: 20.47,    z: -1.40},
        { id: 14, temp: 27.09,  norm: 22.85,    tard: 19.37,    z: -1.20},
        { id: 15, temp: 25.87,  norm: 21.66,    tard: 18.25,    z: -1   },
        { id: 16, temp: 24.54,  norm: 20.44,    tard: 17.07,    z: -0.80},
        { id: 17, temp: 23.09,  norm: 19.16,    tard: 15.81,    z: -0.60},
        { id: 18, temp: 21.5,   norm: 17.8,     tard: 14.44,    z: -0.40},
        { id: 19, temp: 19.77,  norm: 16.33,    tard: 12.94,    z: -0.20},
        { id: 20, temp: 17.94,  norm: 14.75,    tard: 11.36,    z: 0    },
        { id: 21, temp: 16.09,  norm: 13.13,    tard: 9.81,     z: 0.20 },
        { id: 22, temp: 14.3,   norm: 11.56,    tard: 8.42,     z: 0.40 },
        { id: 23, temp: 12.64,  norm: 10.11,    tard: 7.2,      z: 0.60 },
        { id: 24, temp: 11.11,  norm: 8.77,     tard: 6.12,     z: 0.80 },
        { id: 25, temp: 9.69,   norm: 7.52,     tard: 5.13,     z: 1    },
        { id: 26, temp: 8.39,   norm: 6.37,     tard: 4.24,     z: 1.20 },
        { id: 27, temp: 7.2,    norm: 5.33,     tard: 3.46,     z: 1.40 },
        { id: 28, temp: 6.14,   norm: 4.42,     tard: 2.8,      z: 1.60 },
        { id: 29, temp: 5.19,   norm: 3.64,     tard: 2.25,     z: 1.80 },
        { id: 30, temp: 4.36,   norm: 2.99,     tard: 1.82,     z: 2    },
        { id: 31, temp: 3.63,   norm: 2.45,     tard: 1.46,     z: 2.20 },
        { id: 32, temp: 2.99,   norm: 1.99,     tard: 1.18,     z: 2.40 },
        { id: 33, temp: 2.42,   norm: 1.6,      tard: 0.94,     z: 2.60 },
        { id: 34, temp: 1.92,   norm: 1.26,     tard: 0.74,     z: 2.80 },
        { id: 35, temp: 1.47,   norm: 0.96,     tard: 0.57,     z: 3    },
        { id: 36, temp: 1.07,   norm: 0.69,     tard: 0.41,     z: 3.20 },
        { id: 37, temp: 0.72,   norm: 0.46,     tard: 0.28,     z: 3.40 },
        { id: 38, temp: 0.43,   norm: 0.26,     tard: 0.17,     z: 3.60 },
        { id: 39, temp: 0.19,   norm: 0.11,     tard: 0.08,     z: 3.80 },
        { id: 40, temp: 0,      norm: 0,        tard: 0,        z: 4    }
    ]

    if(sexo === 2){
        let arr_prom = mujeres.map(mujer => {
            let resta = parseFloat(parseFloat(mujer.z).toFixed(20)) - parseFloat(parseFloat(indice_mad).toFixed(20))
            return {...mujer, val: Math.abs(resta)}
        })

        const valor_min = Math.min(...arr_prom.map(item_min => {return item_min.val}));
        let arr_filter = arr_prom.filter(item_prom => (item_prom.val) === valor_min)
        
        return arr_filter
    }else{
        let arr_prom = hombres.map(hombre => {
            let resta = hombre.z - indice_mad
            return {...hombre, val: Math.abs(resta)}
        })

        const valor_min = Math.min(...arr_prom.map(item_min => {return item_min.val}));
        let arr_filter = arr_prom.filter(item_prom => item_prom.val === valor_min)
        
        return arr_filter
    }
}

export const isObjectEmpty = (objectName) => {
    return JSON.stringify(objectName) === "{}";
};

export const useContainerDimensions = myRef => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight
    })

    const handleResize = () => {
      setDimensions(getDimensions())
    }

    if (myRef.current) {
      setDimensions(getDimensions())
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [myRef])

  return dimensions;
};