import { Box, Button, Grid2, MenuItem, Typography } from "@mui/material";
import MenuEstatico from "../elementos/Menu";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ReferenceLine from "../componentes/nueva_evaluacion/Graficas";
import { Campos } from "../elementos/Campos";
import { Print } from "@mui/icons-material";
import { Selector } from "../elementos/Selector";
import { useContext, useEffect, useRef, useState } from "react";
import { Pacientes } from "../context";
import { DecimalYear, formatDate, formatInputDate } from "../elementos/formatDate";
import { isObjectEmpty, useContainerDimensions } from "../elementos/Catalogo";
import { ExportarDocumento } from "../componentes/nueva_evaluacion/ExportarDocumento";
import Grafica2 from "../componentes/nueva_evaluacion/Grafica2";

const h_sx = {
    borderLeft: "10px solid #f47432",
    paddingLeft: "15px",
    fontWeight: "bold"   
}

const NuevaEvaluacion = () => {
    const navigate = useNavigate();
    const PacientesContext  = useContext(Pacientes);
    const { paciente, seleccionar_paciente }    = PacientesContext;
    const [remitente, setRemitente] = useState("Dra. Ivonne Azeret Estrada Sánchez")

    const [form, setForm] = useState({
        s_nombre: "",
        n_deporte: 0,
        n_peso: 0,
        n_talla: 0,
        n_tsent: 0,
        n_estAdultEst: 0,
        n_edadPico: 0,
        n_edadEst: 0,
        n_longPiernas: 0,
        n_indiceCormico: 0,
        n_imc: 0,
        n_peso_df: 0,
        n_indiceMad: 0,
        n_clase: 0,
        d_nacimiento: 0,
        n_sexo: 0,
        s_observaciones: "",
        dt_created: Date.now(),
        dt_modificated: Date.now()
    })

    let {id} = useParams()
    const contentRef = useRef();

    useEffect(()=> { 
        console.log("loading")       
        if(!isObjectEmpty(paciente)){
           setForm(paciente)
        }else{
            seleccionar_paciente(id)
        }
        
    },[paciente])

    const refGrid = useRef(null);
    const { width } = useContainerDimensions(refGrid)

    return(<MenuEstatico acciones={(<Box sx={{display: "flex"}}>
        <Button onClick={e=> {
            navigate("/evaluaciones")
        }} variant="filled" sx={{
            background: "#f47432",
            fontSize: "12px",
            textTransform: "none",
            color: "white",
            fontWeight: "bold"
        }}><ArrowBackIcon sx={{fontSize: "14px", marginRight: "10px"}}/>Regresar</Button>
        <ExportarDocumento
            contentRef={contentRef}
            validacion={()=>{return true}}
            data={{
                nombre: form?.s_nombre ? form.s_nombre: "",
                remitente: remitente,
                indice_mad: form?.n_indiceMad ? parseFloat(form.n_indiceMad).toFixed(2): 0,
                clase: form?.n_clase? form.n_clase: 0,
                titulo: "Dr. Ciencias | Master Nut. Deportiva",
                edad_est: form?.n_edadEst ? form.n_edadEst: 0,
                est_estimada: form?.n_estAdultEst ? form.n_estAdultEst: 0,
                edad_pico: form?.n_edadPico ? form.n_edadPico: 0,
                edad_nac: DecimalYear(form?.dt_created ? form.dt_created: 0, form?.d_nacimiento ? form.d_nacimiento: 0),
                observaciones: form?.s_observaciones ? form.s_observaciones: null
            }}
        />
    </Box>)}>
        <Grid2 container>
            <Grid2 ref={refGrid} id="grid-container" size={{xs: 12, md: 12, xl: 6, lg: 12}}>
                <Box ref={contentRef}>
                    <Grafica2
                        edadEst={form?.n_edadEst ? parseFloat(form.n_edadEst).toFixed(2): 0}
                        nombre={form?.s_nombre ? form.s_nombre: ""}
                        width={width}
                        sexo={form?.n_sexo ? form.n_sexo: 1}
                        edadPico={form?.n_edadPico ? form.n_edadPico: 0}
                    />
                    {/* <ReferenceLine nombre={form?.s_nombre ? form.s_nombre: ""} edadPico={form?.n_edadPico ? form.n_edadPico: 0}/> */}
                </Box>
                <Box sx={{paddingX: "50px"}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={8}>
                            <Typography sx={{fontSize: "14px"}}>Nombre</Typography>
                            <Campos disabled={true} name="s_nombre" value={form?.s_nombre ? form.s_nombre: ""} align="left"/>
                        </Grid2>
                        <Grid2 size={4}>
                            <Typography sx={{fontSize: "14px"}}>Fecha de Nacimiento</Typography>
                            <Campos value={form?.d_nacimiento ? formatInputDate(form.d_nacimiento): new Date(Date.now())} disabled={true} type="date"/>
                        </Grid2>
                    </Grid2>
                </Box>
                <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                    <Grid2 container spacing={2}>
                        <Grid2 size={4}>
                            <Typography sx={{fontSize: "14px"}}>Sexo</Typography>
                            <Selector disabled={true} value={form?.n_sexo ? form.n_sexo: 101}>
                                <MenuItem value={101}><em>Seleccione el sexo</em></MenuItem>
                                <MenuItem value={1}>Masculino</MenuItem>
                                <MenuItem value={2}>Femenino</MenuItem>
                            </Selector>
                        </Grid2>
                        <Grid2 size={8}>
                            <Typography sx={{fontSize: "14px"}}>Deporte</Typography>
                            <Selector disabled={true} value={form?.n_deporte ? form.n_deporte: 101}>
                                <MenuItem value={101}><em>Seleccione un deporte</em></MenuItem>
                                <MenuItem value={1}>Fútbol</MenuItem>
                                <MenuItem value={2}>Básquetbol</MenuItem> 
                                <MenuItem value={3}>Natación</MenuItem> 
                                <MenuItem value={4}>Atletismo</MenuItem>
                                <MenuItem value={5}>Taekwondo</MenuItem>
                                <MenuItem value={6}>Karate</MenuItem>
                                <MenuItem value={7}>Rugby</MenuItem>
                            </Selector>
                        </Grid2>
                    </Grid2>
                </Box>
            </Grid2>
            <Grid2 sx={{marginTop: "50px"}} size={{xs: 12, md: 12, xl: 6, lg: 12}}>
                <Box className="scrollBloque" sx={{overflowY: "scroll", height: "70vh"}}>
                    <Typography sx={h_sx}>Datos Básicos</Typography>
                    <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Peso (kg)</Typography>
                            <Campos disabled={true} type="number" name="n_peso" value={form?.n_peso ? form.n_peso: 0} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Talla (cm)</Typography>
                            <Campos disabled={true} type="number" name="n_talla" value={form?.n_talla ? form.n_talla: 0} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Tsent (cm)</Typography>
                            <Campos disabled={true} type="number" name="n_tsent" value={form?.n_tsent ? form.n_tsent: 0} align="left"/>
                        </Box>
                    </Box>
                    <Typography sx={h_sx}>Estimaciones</Typography>
                    <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Clase</Typography>
                            <Campos disabled={true} value={form?.n_clase == 1 ? "TEMP": form?.n_clase == 2 ? "NORM": "TARD"} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Falta (cm)</Typography>
                            <Campos disabled={true} name="n_falta" value={form?.n_falta ? form.n_falta: 0} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Est adult est</Typography>
                            <Campos disabled={true} name="n_estAdultEst" value={form?.n_estAdultEst ? form.n_estAdultEst: 0} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Edad est</Typography>
                            <Campos name="n_edadEst" disabled={true} value={form?.n_edadEst ? parseFloat(form.n_edadEst).toFixed(2): 0} align="left"/>
                        </Box>
                    </Box>
                    <Typography sx={h_sx}>Derivadas de cálculo</Typography>
                    <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Longitud piernas</Typography>
                            <Campos type="number" disabled={true} name="n_longPiernas" value={form?.n_longPiernas ? form.n_longPiernas: 0} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Índice córmico</Typography>
                            <Campos name="n_indiceCormico" value={`${form?.n_indiceCormico ? form.n_indiceCormico: 0.0}%`} disabled={true} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>IMC</Typography>
                            <Campos name="n_imc" disabled={true} value={form?.n_imc ? form.n_imc: 0} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Índice Mad</Typography>
                            <Campos name="n_indiceMad" value={parseFloat(form?.n_indiceMad ? form.n_indiceMad: 0).toFixed(1)} disabled={true} align="left"/>
                        </Box>
                        <Box sx={{marginBottom: "20px"}}>
                            <Typography sx={{fontSize: "14px"}}>Edad Pico</Typography>
                            <Campos name="n_edadPHV" disabled={true} value={form?.n_edadPico ? form.n_edadPico: 0} align="left"/>
                        </Box>
                    </Box>
                    <Typography sx={h_sx}>Observaciones</Typography>
                    <Box sx={{paddingX: "50px", marginTop: "10px"}}>
                        <Box sx={{marginBottom: "20px"}}>
                            <Campos
                                disabled={true}
                                name="s_observaciones"
                                rows={5} align="left"
                                multiline={true}
                                value={form?.s_observaciones ? form.s_observaciones: "Sin Observaciones"}
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid2>
        </Grid2>
    </MenuEstatico>)
}

export default NuevaEvaluacion