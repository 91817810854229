import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Dashboard from './pages/Dashboard';
import Evaluaciones from './pages/Evaluaciones';
import NuevaEvaluacion from './pages/NuevaEvaluacion';
import VerEvaluacion from './pages/VerEvaluacion';
import Login from './pages/Login';
import Auth from './context/auth/states';
import tokenAuth from './config/tokenAuth';
import Pacientes from './context/pacientes/states';

const token = localStorage.getItem('token');
if(token){
  tokenAuth(token);
}

function App() {
  return (<Auth>
    <Pacientes>
      <Router>
        <Routes>
          <Route exact path="/login"                Component={Login}/>
          <Route exact path="/dashboard"            Component={Dashboard}/>
          <Route exact path="/evaluaciones"         Component={Evaluaciones}/>
          <Route exact path="/evaluaciones/crear"   Component={NuevaEvaluacion}/>
          <Route exact path="/evaluaciones/ver/:id" Component={VerEvaluacion}/>
          <Route exact path="/"                     Component={Dashboard}/>
          <Route path="/*" element={<Navigate replace to="/dashboard" />} />
        </Routes>
      </Router>
    </Pacientes>
  </Auth>
  );
}

export default App;
