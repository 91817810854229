// AUTHENTICATION
export const SECCIONES_MODULO   = 'SECCIONES_MODULO';
export const REGISTRO_EXITOSO   = "REGISTRO_EXITOSO"
export const REGISTRO_ERROR     = "REGISTRO_ERROR"
export const OBTENER_USUARIO    = "OBTENER_USUARIO"
export const LOGIN_EXITOSO      = "LOGIN_EXITOSO"
export const LOGIN_ERROR        = "LOGIN_ERROR"
export const CERRAR_SESION      = "CERRAR_SESION"
export const REGISTRO_ERROR_MSG = "REGISTRO_ERROR_MSG"
export const AGREGAR_TOKEN      = "AGREGAR_TOKEN"

// PACIENTES
export const OBTENER_PACIENTES      = "OBTENER_PACIENTES"
export const CREAR_PACIENTES        = "CREAR_PACIENTES"
export const EDITAR_PACIENTES       = "EDITAR_PACIENTES"
export const ELIMINAR_PACIENTES     = "ELIMINAR_PACIENTES"
export const SELECCIONAR_PACIENTE   = "SELECCIONAR_PACIENTE"




